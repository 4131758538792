function checkHuurtarief(item, data){
	// Haal het huurtarieftype op – betreft het een vast of variabel huurtarief?
	var huurtarieftype = VARIABLES.get('PRODUCTEN')[data.type]['huurtarief'];
	var go = false;

	// Als er meerdere huurtarieven zijn, lussen we over de verschillende mogelijkheden
	if(typeof huurtarieftype == 'object'){
		var possibleHuurtarief = data.huurtarief.multiple;
		var savedHuurtarief = item.huurtarief;
		var parameter = item[VARIABLES.get('PRODUCTEN')[data.type]['huurtarief']['parameter']];

		$.each(possibleHuurtarief, function(key, value){
			if(value.label == parameter && value.huurtarief == savedHuurtarief){
				//console.log(value.label + " = " + parameter + "?");
				go = true;
			}
		});
	}
	// Als er voor dit product slechts één huurtarief is, vergelijken we deze met het huurtarief uit de lokale opslag
	else if(item.huurtarief == data[VARIABLES.get('PRODUCTEN')[data.type]['huurtarief']]['single']){
		go = true;
	}

	// Als er met het huurtarief is geknoeid ...
	if(go === false){
		// Gooi het in de localStorage bewaarde product weg
		removeFromStorage(item.id, item.timestamp, 'shopping');
	}

	return go;
}