//var activeIndex = 0;
localStorage.setItem('activeIndex', 0);

function getActiveIndex(direction, current){
	if(direction === 'previous'){
		if(current === 0) {
			index = getStorage('comparison').length - 1;
		}
		else{
			index = current - 1;
		}
	}
	if(direction === 'next'){
		if(current === getStorage('comparison').length - 1){
			index = 0;
		}
		else{
			index = current + 1;
		}
	}

	return index;
}

function setActiveIndex(direction){
	//console.log(direction);
	//console.log(parseInt(localStorage.getItem('activeIndex')));

	if(direction === 'reset'){
		localStorage.setItem('activeIndex', 0);
	}

	if(direction === 'previous'){
		if(parseInt(localStorage.getItem('activeIndex')) === 0){
			//console.log('activeIndex was 0');

			//activeIndex = getStorage('comparison').length - 1;
			localStorage.setItem('activeIndex', getStorage('comparison').length - 1);
		}
		else{
			localStorage.setItem('activeIndex', parseInt(localStorage.getItem('activeIndex')) - 1);
		}
	}

	if(direction === 'next'){
		//console.log('activeIndex uitlezen');
		//console.log(getStorage('comparison').length - 1);

		if(parseInt(localStorage.getItem('activeIndex')) === getStorage('comparison').length - 1){

			//activeIndex = 0;
			localStorage.setItem('activeIndex', 0);
		}
		else{
			//activeIndex++;
			localStorage.setItem('activeIndex', parseInt(localStorage.getItem('activeIndex')) + 1);
		}
	}

	//console.log('activeIndex is nu' + parseInt(localStorage.getItem('activeIndex')));
}

function setDataOnPreviewPage(index){
	//console.log('in setDataOnPreviewPage');
	var comparison = $('.modal-comparison .comparison');
	var product = $(comparison).find('article.product');

	$(product).data('id', getStorage('comparison')[index].id);

	// We resetten de inhoud van het kaartje
	$(product).find('.card-header .pager').addClass('disabled');
	$(product).find('.card-header .pager button').prop('disabled', true);
	$(product).find('.card-body').html(CONSTANTS.get('TEMPLATES').load.loading.body);
	$(product).find('.card-header h5').html('<span class="badge badge-light">' + CONSTANTS.get('TEMPLATES').load.loading.title + '</span>');
	$(product).find('.card-img img').css('opacity', '.5');
	$(product).removeClass(function(index, className){
		return (className.match(/\bwagentype-\S+/g) || []).join(' ');
	});

	storage = getStorage('comparison');

	var order = [];

	$.each(storage, function(index, item){
		order.push(item.id);
	});

	//console.log('ORDER');
	//console.log(order);

	var producten = [];

	getFromServer('comparison', function(result){
		if(!result){
			queueMessage('Er trad een <strong>technische fout</strong> op. Probeer het nog eens.', 'Oeps!', type = 'danger');

			$('.modal').modal('hide');
			return false;
		}

		$(product).empty();

		var producten = [];
		var templateSource = templates['comparison']['modal'];

		//console.log('result');
		//console.log(result);

		//console.log(templateSource);

		$.each(result, function(index, item){
			//console.log(item);
			var templatez = templateSource[item.data.type];
			producten.push(buildTemplate(item, templatez));
		});

		//console.log('producten');
		//console.log(producten);

		producten.sort(function(a, b) {
			return parseFloat(a.timestamp) - parseFloat(b.timestamp);
		});

		//console.log('producten gesorteerd');
		//console.log(producten);

		

		$.each(producten[index].value, function(i, val){
			$(product).append(val);
		});

		// order result

		//console.log('RESULT');
		//console.log(result);

		result.sort((a, b)=> order.indexOf(a.data.id) - order.indexOf(b.data.id));

		//console.log('RESULT ORDERED');
		//console.log(result);

		/*$.each(result, function(index, item){
			$.each(producten, function(index2, item2){
				if(item.id == item2.id){
					$(product).append(producten);
				}
			});
		});*/

		// We koppelen de juiste klasses aan het kaartje
		$(product).removeClass();
		$(product).addClass('product card');

		$(product).data('type', result[index].data.type);
		$(product).attr('data-type', result[index].data.type);

		//console.log(VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type]);

		


		if(VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type] !== false){
			if(Array.isArray(result[index].data[VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type]])){
				$.each(result[index].data[VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type]], function(indexProductparameter, productparameter){
					$(product).addClass(VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type] + '-' + productparameter.slug);
				});
			}
			else{
				//console.log('hier');
				//console.log(result[index]);
				//console.log(result[index].data.type);
				//console.log(result[index].data[VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type]]);
				//console.log(result[index].data[VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type]]['slug']);
				//console.log(VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type]);
				$(product).addClass(VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type] + '-' + result[index].data[VARIABLES.get('PRODUCTPARAMETERS')[result[index].data.type]]['slug']);
			}
		}

		// Housekeeping!
		$(product).find('[data-bind="content"]').each(function(){
			$(this).data('truncateUrl', result[0].data.url);
			truncate($(this));
		});

		if(getStorageSize(storage) == 1){
			$(product).find('.card-header .pager').addClass('disabled');
			$(product).find('.card-header .pager button').prop('disabled', true);
		}
		else{
			$(product).find('.card-header .pager').removeClass('disabled');
			$(product).find('.card-header .pager button').prop('disabled', false);
		}
	});
}

function setControllers(){
	//var self = this;

	var comparison = $('.comparison');
	var product = $(comparison).find('.product');

	$(comparison).on('click', 'a', function(event){
		var that = this;
		event.preventDefault();
		$(this).closest('.modal').modal('hide');
		$(this).closest('.modal').on('hidden.bs.modal', function(event){
			var location = that.href;
			window.location.href = location;
		})
	});

	$(comparison).on('click', '.pager button:first-of-type', function(){
		setActiveIndex('previous');
		//setDataOnPreviewPage(self.activeIndex)
		setDataOnPreviewPage(localStorage.getItem('activeIndex'));
	});
	$(comparison).on('click', '.pager button:last-of-type', function(){
		setActiveIndex('next');
		//setDataOnPreviewPage(self.activeIndex)
		setDataOnPreviewPage(localStorage.getItem('activeIndex'));
	});
}