function breathe(target, times = -1, delay = 1500, speed = 1000){
	$(target).addClass('breathe in');

	var counter = 0;
	var interval = setInterval(function(){
		setTimeout(function(){
			if(counter < times){
				$(target).toggleClass('in');
				$(target).toggleClass('out');
				counter++;
			}
			else{
				window.clearInterval(interval);
				$(target).removeClass('breathe');
				$(target).removeClass('in');
				$(target).removeClass('out');
			}
		}, speed);
	}, delay);
}