// Verzorg een vlotte schuifbeweging naar het paginadoel
var scroll = new SmoothScroll('a[href*="#"]', {
	topOnEmptyHash: true,
	speed: 500,
	speedAsDuration: true,
	easing: 'easeInOutQuad',
	ignore: '[data-scroll-ignore], .nav-link',
	offset: function(anchor, toggle){
		/*return $('.navbar').outerHeight();*/
		return 0;
	}
});

$('body').on('click', '.dropdown-toggle', function(event){
	$(this).next('.dropdown-menu').toggle();
	});

$('body').on('click', function(event){
	var target = event.target; 
	if(!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')){
		$('.dropdown-menu').hide();
	}
});

$(function(){
	$('.navigation.primary').html($('<ul>', { class: 'navbar-nav' }));
	
	$('.navigation.secondary ul > li.primary > a').each(function(index){
		var a = $('<a>', { href: $(this).attr('href') });
		var span = $('<span>', { class: 'badge' }).html($(this).text());
		var li = $('<li>', { class: 'nav-item' });

		var element = $(a).html(span);
		element = $(li).html(element);
		
		$('.navigation.primary ul').append(element);
	});
});

// Streepjescode
$(function(){
	var barcode = $("<div />");
	var size = 50;
	var multiplier = 1;

	for(var i = 0; i <= 50; i++){
		if(i == 0 || i == size){
			var multiplier = 0;
		}
		else{
			var multiplier = 1;
		}

		var element = $("<span />")
			.addClass("digit digit-" + Math.floor(Math.random() * 5 ) + " space-" + Math.floor(Math.random() * 5 * multiplier ))
			.html('');
		$(barcode).append(element);
	}

	$('.barcode').each(function(index){
		$(this).append(barcode.clone());
	});
});

$('.imagery .imagery-pager button, .imagery .imagery-media img').on('click', function(event){
	var pager = false;
	var direction = false;
	var target = false;
	var slide = false;
	var media = $(this).parents('.imagery').children('.imagery-media');

	if($(this).parents('.imagery-pager').length){
		pager = $(this).parents('.imagery-pager');
	}
	else{
		identifier = $(this).closest('.imagery-media').attr('id');
		pager = $('.imagery-pager').filter('[data-imagery-target=' + '#' + identifier + ']')
		var elm = $(this);
		var xPos = event.pageX - elm.offset().left;

		if((elm.width() / 2) >= xPos){
			direction = 'previous';
		} else {
			direction = 'next';
		}
	}

	var length = $(pager).find('[data-imagery-slide]').length;

	if(length <= 1){
		return false;
	}

	if(typeof $(pager).data('imagery-target') !== 'undefined'){
		var target = $($(pager).data('imagery-target'));
	}
	else{
		var target = $(this).closest('.imagery').children('.imagery-media');
	}

	if(direction || $(this).hasClass('previous') || $(this).hasClass('next')){
		if(direction == 'previous' || $(this).hasClass('previous')){
			current = $(target).find('.imagery-media-item.show').attr('id');
			previous = false;
			if($('#' + current).prev('.imagery-media-item').length > 0){
				previous = $('#' + current).prev('.imagery-media-item').attr('id');
				
			}
			if(previous){
				slide = previous;
			}
			else{
				slide = $('#' + current).nextAll('.imagery-media-item').last().attr('id');
			}
		}
		if(direction == 'next' || $(this).hasClass('next')){
			current = $(target).find('.imagery-media-item.show').attr('id');
			next = false;
			if($('#' + current).next('.imagery-media-item').length > 0){
				next = $('#' + current).next('.imagery-media-item').attr('id');
			}
			if(next){
				slide = next;
			}
			else{
				slide = $('#' + current).prevAll('.imagery-media-item').last().attr('id');
			}
		}
	}
	else{
		if(length > 1 && typeof $(this).data('imagery-slide') !== 'undefined'){
			slide = $(this).data('imagery-slide');
		}
	}

	$(media).find('[data-imagery-media-type="movie"].show').each(function(index){
		src = $(this).find('iframe').attr('src');
		src = src.replace('?autoplay=1', '');
		$(this).find('iframe').attr('src', '');
		$(this).find('iframe').attr('src', src);
	});
	$(media).find('.imagery-media-item').removeClass('show').addClass('hide');
	$('#' + slide).removeClass('hide').addClass('show');

	if($('#' + slide).data('imagery-media-type') == 'movie'){
		src = $('#' + slide).find('iframe').attr('src');
		
		$('#' + slide).find('iframe').attr('src', '');
		$('#' + slide).find('iframe').attr('src', src + '?autoplay=1');
	}

	var active = $(pager).find('[data-imagery-slide="' + slide + '"]');
	$(pager).find('[data-imagery-slide]').removeClass('active');
	$(active).addClass('active');
});


$(function(){
	var pager = $('.imagery .imagery-pager');
	var length = $(pager).find('button').not('.previous, .next').length;

	$(pager).find('button').not('.previous, .next').first().addClass('active');

	if(length == 1){
		$(pager).find('button').filter('.previous, .next').prop('disabled', true);
	}
});

// Toon verborgen items uit een lijst
$('.toggle-list').on('click', function(event){
	$(this).tooltip('hide');
	target = $(this).data('target');
	$('#' + target).children('li:nth-child(1n+4)').toggle();
	$(this).remove();
});

$(function(){
	// Verschuif naar het paginadoel
	var target = location.hash;
	$('a[href="' + target + '"]').click();

	// Controleer of het cookieakkoord werd gegeven
	if(localStorage.getItem('cookies') === null){
		var cookies = toastr.info('<p>Om je voorkeuren te onthouden en deze website te verbeteren, maken we gebruik van cookies. Meer informatie vind je in onze <a href="/privacyverklaring">privacyverklaring</a>.</p><p class="float-right"><button type="button" class="clear btn btn-primary">Geef toestemming</button></p>', 'Let op!', { "timeOut": "0", "extendedTimeOut": "0", "tapToDismiss" : false });
		if(cookies.find('.clear').length){
			cookies.delegate('.clear', 'click', function(){
				toastr.clear(cookies, { force: true });
				localStorage.setItem('cookies', true);
			});
		}
	}

	// Initaliseer carousels
	$('.carousel').carousel({
		interval: 5000,
		ride: 'carousel'
	});

	// Animeer hoogteverschillen voor carousels
	$('.carousel').on('slide.bs.carousel', function(event){
		var nextH = jQuery(event.relatedTarget).height();
		jQuery(this).find('.active.carousel-item').parent().animate({
			height: nextH
		}, 500);
	});

	// Initaliseer tooltips
	$('body').tooltip({
		selector: '[data-toggle="tooltip"]',
		trigger : 'hover'
	});

	// Kort lange titels af
	$('.ellipsis').each(function(){
		ellipsis($(this));
	});

	// Initaliseer pop-overvensters
	/*$('[data-toggle=popover]').on('click', function(event){
		event.preventDefault();
	})
	.popover({
		html: true,
		sanitize: false
	}).on('inserted.bs.popover', function(){
		$('.popover').addClass($(this).data('popover-class'));
	}).on('shown.bs.popover', function(){
		$('.popover-body a.btn').on('click', function(){
			$('[data-toggle="popover"]').each(function(){
				$(this).popover('hide');
			});
		});;
	});*/
	
	/*$(window).on('scroll', function(event){
		$('.popover').popover('hide');
	});*/

	// Kort lange teksten in
	$('.truncate').each(function(){
		truncate($(this));
	});

	// Kort lange lijsten in
	$('.abate').each(function(){
		abate($(this));
	});

	// Activeer mediagalerijen
	$('.imagery-media').each(function(){
		imagery($(this));
	});

	// Verzorg asynchrone infovensters
	$('body').on('click', '[data-toggle="modal"][data-type="asynchronous"]', function(event){
		event.preventDefault();

		var style = 'modal-info modal-asynchronous';

		if(typeof $(this).data('style') !== 'undefined'){
			var style = style.concat(' ').concat($(this).data('style'));
		}

		var content = $(this).attr('href');
		var dialog = bootbox.dialog({
			className: style + ' ' + 'content',
			size: 'xl',
			onEscape: true,
			backdrop: true,
			title: CONSTANTS.get('TEMPLATES').load.loading.title,
			message: CONSTANTS.get('TEMPLATES').load.loading.body,
		});

		dialog.bind('shown.bs.modal', function(){
			$.getJSON(content + '.json').done(function(data){
				dialog.addClass(data.slug);
				dialog.find('.modal-title').text(data.title);
				dialog.find('.modal-body').addClass(data.template);
				dialog.find('.modal-body').html(data.content);
			}).fail(function(){
				dialog.find('.modal-title').text(CONSTANTS.get('TEMPLATES').load.fail.title);
				dialog.find('.modal-body').html(CONSTANTS.get('TEMPLATES').load.fail.body);
			});

			$(this).on('click', 'a', function(event){
				var that = this;
				event.preventDefault();
				$(this).closest('.modal').modal('hide');
				$(this).closest('.modal').on('hidden.bs.modal', function(event){
					var location = that.href;
					window.location.href = location;
				})
			});
		});
	});

	// Verzorg meldingsvensters
	$('body').on('click', '[data-toggle="modal"][data-type="alert"]', function(event){
		event.preventDefault();

		var target = $(this).data('target');

		var style = 'modal-info';

		if(typeof $('.alert').filter('[data-alert="' + target + '"]').data('alert-type') !== 'undefined'){
			var style = 'modal-' + $('.alert').filter('[data-alert="' + target + '"]').data('alert-type');
		}
		
		var content = $('.alert').filter('[data-alert="' + target + '"]').clone(true).find('h3').remove().end().html();
		var title = $('.alert').filter('[data-alert="' + target + '"]').find('h3').html();

		var dialog = bootbox.dialog({
			className: 'alert' + ' ' + style,
			size: 'md',
			onEscape: true,
			backdrop: true,
			title: title,
			message: content,
			centerVertical: true,
			buttons: [
				{
					label: 'Sluit',
					className: 'btn-secondary'
				}
			],
			onEscape: true
		});

		dialog.bind('shown.bs.modal', function(){
			$(this).on('click', 'a', function(event){
				var that = this;
				event.preventDefault();
				$(this).closest('.modal').modal('hide');
				$(this).closest('.modal').on('hidden.bs.modal', function(event){
					if(jQuery.isEmptyObject($(that).data())){
						var location = that.href;
						window.location.href = location;
					}
					else{
						$(that).trigger('click');
						$('body').addClass('modal-open');
					}
				})
			});
		});

		dialog.modal('show');
		
	});

	// Verzorg configuratievenster
	$('.configure').on('click', function(event){
		event.preventDefault();

		var element = this;
		var data = $(element).data();

		var itemData = $('[data-id="' + data.product + '"]').data();
		var item = $('[data-id="' + itemData.id + '"]');

		// dit kan eigl altijd dezelfde functie zijn, maar aangezien die per pagina kan verschillen
		// als geen callback, dan onmiddellijk addtostorage
		var callback = $(element).attr('data-callback');
		if(typeof callback == 'undefined'){
			addToStorage(itemData.id, 'shopping');
			return true;
		}

		var content = $(this).data('target');
		var dialog = bootbox.dialog({
			className: 'modal-configuration',
			size: 'md',
			onEscape: true,
			backdrop: true,
			title: CONSTANTS.get('TEMPLATES').load.loading.title,
			message: CONSTANTS.get('TEMPLATES').load.loading.body,
			buttons: {
				cancel: {
					label: 'Annuleer',
					className: 'btn-light'
				},
				ok: {
					label: '<i class="fas fa-shopping-bag" aria-hidden="true"></i> Voeg toe aan je boodschappenlijst',
					className: 'btn-success',
					callback: function(){
						var form = dialog.find('form');
						var datasdf = $(form).serializeArray();
						$.each( datasdf, function( key, value ) {
							$(item).data(value.name, value.value);
							$(item).attr('data-' + value.name, value.value);
							localStorage.setItem(value.name, value.value);
						});
						addToStorage(itemData.id, 'shopping');
					}
				}
			}
		});

		dialog.bind('shown.bs.modal', function(){
			template = templates['configuration'][itemData.type];
			
			var content = Mustache.render(template, itemData);
			dialog.find('.bootbox-body').html(content);
			dialog.find('.modal-header').find('h5').html(data.title);
			
			if(typeof window[callback] == 'function'){
				window[callback].call(itemData);
			}
		});
	});
});

$('body').on('click', '.storage button, a[data-storage]:not(.remove)', function(event){
	event.preventDefault();

	bootbox.hideAll();

	storageLocation = $(this).data('storage');
	storage = getStorage(storageLocation);

	if(storageLocation == 'shopping'){
		var dialog = $('.offcanvas.right');

		$(dialog).find('[data-draw-id="boodschappenlijst"]').data('initView', 'true');
		$(dialog).find('[data-draw-id="boodschappenlijst"]').attr('data-init-view', 'true');

		$(dialog).find('[data-draw-id="boodschappenlijst"]').data('drawStatus', 'live');
		$(dialog).find('[data-draw-id="boodschappenlijst"]').attr('data-draw-status', 'live');

		$(dialog).find('[data-draw-id="boodschappenlijst"]').dataTrigger('trigger', Math.round((new Date()).getTime() / 1000));
	}

	if(storageLocation == 'comparison'){
		if(getStorageSize(storage) > 0){
			var dialog = bootbox.dialog({
				className: 'modal-' + storageLocation + ' ' + 'modal-info' + ' ' + 'products',
				size: 'md',
				onEscape: true,
				backdrop: true,
				title: allowedStorageLocations[storageLocation]['title'],
				message: CONSTANTS.get('TEMPLATES').load.loading.body
			});

			dialog.init(function(){
				template = templates['comparison']['modal']['container'];

				content = $.parseHTML($.trim(template));

				total = $(template).wrapAll('<div>');
				$(total).find('article').html(content);
				dialog.find('.bootbox-body').html($(total).parent().html());

				dialog.find('.modal-header, .modal-footer').remove();

				setControllers();
				setDataOnPreviewPage(localStorage.getItem('activeIndex'));

				$('[data-toggle="tooltip"]').tooltip({
					trigger : 'hover'
				});
			});
		}
		else{
			data = [
				{
					storageLocation: allowedStorageLocations[storageLocation]['label'],
					product: VARIABLES.get('PRODUCTEN')[VARIABLES.get('HOOFDPRODUCT')]
				}
			];
	
			var template = templates['comparison']['teaser']['empty'];
			var content = Mustache.render(template, data);
	
			var dialog = bootbox.confirm({
				title: allowedStorageLocations[storageLocation]['label'],
				message: content,
				className: 'modal-info',
				size: 'lg',
				centerVertical: true,
				buttons: {
					confirm: {
						label: 'Naar de ' + VARIABLES.get('PRODUCTEN')[VARIABLES.get('HOOFDPRODUCT')]['tekstlabel']['plural'],
						className: 'btn-primary'
					},
					cancel: {
						label: 'Annuleer',
						className: 'btn-secondary'
					}
				},
				callback: function(result){
					if(result){
						$(this).closest('.modal').modal('hide');
						$(this).closest('.modal').on('hidden.bs.modal', function(event){
							window.location.href = VARIABLES.get('PRODUCTEN')[VARIABLES.get('HOOFDPRODUCT')]['url'];
						})
					}
				}
			});
		}
	}
})

// Als de zijbalk wordt verborgen ...
$('body').on('click', '.offcanvas-overlay', function(event){
	var dialog = $('.offcanvas.right');

	$(dialog).find('[data-draw-id="boodschappenlijst"]').data('drawStatus', 'idle');
	$(dialog).find('[data-draw-id="boodschappenlijst"]').attr('data-draw-status', 'idle');

	$(dialog).find('[data-draw-id="boodschappenlijst"]').data('trigger', 'false');
	$(dialog).find('[data-draw-id="boodschappenlijst"]').attr('data-trigger', 'false');
});

// Als er op een link in de zijbalk wordt geklikt, verbergen we eerst de zijbalk vooraleer we de link volgen
$('.offcanvas.right').on('click', 'a[href]:not([data-toggle="modal"][data-type="asynchronous"])', function(event){
	event.preventDefault();
	var target = this;

	$('.offcanvas-overlay').click();

	setTimeout(function() { 
        window.location.href = target.href;;
    }, 500);
});

// Herbereken titelafkorting
$(window).on('resize', function(){
	$('.ellipsis').each(function(){
		ellipsis($(this));
	});
});

$('body').on('click', '.toggle-details', function(event){
	$(this).find('i').toggleClass('fa-long-arrow-alt-down fa-long-arrow-alt-up')
	$(this).parents('.list-group-item').find('.details').toggleClass('show');
});

$('body').on('click', 'button.remove.confirmation, a.remove.confirmation', function(event){
	var element = $(this);
	var html = $(this).html();
	var storage = $(element).data('storage');
	var id = $(element).data('id');
	var data = getFromStorage(id, storage);

	$(element).addClass('confirming');

	if($(element).data('confirmation') == 'true'){
		removeFromStorage($(element).data('id'), $(element).data('timestamp'), $(element).data('storage'));

		var messages = [];
		messages.push("Je <strong>verwijderde</strong> het " + data.merk + " " + data.title + "-product uit je " + allowedStorageLocations[storageLocation]['label'] + ".");

		if(getStorage(storage).length == 0){
			messages.push('<strong>Je ' + allowedStorageLocations[storageLocation]['label'] + ' is weer leeg.</strong>');

			if(storage == 'comparison'){
				$('.modal').modal('hide');
			}
		}
		else{
			if(storage == 'comparison'){
				setDataOnPreviewPage(getStorage('comparison').length - 1);
			}
		}

		queueMessage(messages.join(' '), 'Gelukt!', type = 'success');
	}
	else{
		$(element).data('confirmation', 'true');
		$(element).attr('data-confirmation', 'true');
		$(element).html($(element).data('confirmation-text'));

		setTimeout(function(){
			$(element).removeClass('confirming');
			$(element).data('confirmation', 'false');
			$(element).attr('data-confirmation', 'false');
			$(element).html(html);
		}, 5000);
	}
});

$('body').on('click', 'a.read-more.abatify', function(event){
	event.preventDefault();

	var target = false;

	if(typeof $(this).data('abate-target') !== 'undefined'){
		var target = $(this).data('abate-target');
	}

	if($(target).length){
		$('[data-toggle="tooltip"]').tooltip('hide');

		$(this).find('i').toggleClass('fa-plus-circle fa-minus-circle');

		if($(target).hasClass('expanded')){
			$(target).removeClass('expanded');
			abate(target);
		}
		else{
			$(target).addClass('expanded')
			$(target).find('li').removeAttr('style').removeClass();
		}
	}
});

$('body').on('click', '[data-toggle="collapse"]', function(event){
	$('body').find('[data-target="' + $(this).data('target') + '"]').each(function(){
		if($(this).find('i')){
			icon = $(this).find('i').toggleClass('fa-plus-circle fa-minus-circle');;
		}
	});
});