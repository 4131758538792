// Validatieopties
var validateConfig = {
	validClass: 'valid',
	errorClass: 'invalid',
	errorPlacement: function(error, element){
		return true;
	},
	highlight: function(element){
		$(element).closest('.form-group').addClass('field-error');
	},
	unhighlight: function(element){
		$(element).closest('.form-group').removeClass('field-error');
	},
	invalidHandler: function(event, validator){
		queueMessage(CONSTANTS.get('TEMPLATES').form.fail.prefix, 'Let op!', type = 'danger');
	}/*,
	ignore: '*'*/
};

// Form stepped

$('a.nav-link').on('show.bs.tab', function(event){
	var $target = $(event.target);
	if($target.hasClass('disabled')){
		event.preventDefault();
	}
});

function buildUI(storage){
	if(!storage){
		showEmptyWarning('shopping');

		var data = 
			{
				message: 'Je <strong>' + allowedStorageLocations['shopping']['label'] + '</strong> is momenteel nog <strong>leeg.</strong>',
				product: VARIABLES.get('PRODUCTEN')[VARIABLES.get('HOOFDPRODUCT')]
			}
		;
		var template = templates['storage']['empty'];

		var content = Mustache.render(template, data);
		$(shopping['teaser']).find('> .storage').html(content);

		$(shopping['textarea']).val('Adviseer me!');

		return false;
	}
	else{
		$(shopping['textarea']).val('');
		// Voor elk item in de localStorage ...

		var producten = {};
		producten['textarea'] = [];
		producten['teaser'] = [];
		var count = 0;

		// Haal elk product in de opslag op
		$.each(storage, function(index, item){
			producten.textarea.push(buildTemplate(item, templates['shopping']['textarea'][item.data.type], 'text'));
			producten.teaser.push(buildTemplate(item, templates['shopping']['teaser'][item.data.type]));
		});

		sortStorage(producten.textarea, 'timestamp');
		sortStorage(producten.teaser, 'timestamp');

		// container toevoegen
		$(shopping['teaser']).find('.storage').find('> .content').html(templates.shopping.teaser.container);

		$.each(producten.textarea, function(key, product){
			var text = product.value;

			$(shopping['textarea']).val(function(i, text) {
				return text + product.value;
			});
			$(shopping['textarea']).val($(shopping['textarea']).val());
		});
		$(shopping['textarea']).val($(shopping['textarea']).val().trim());

		$.each(producten.teaser, function(key, product){
			if(key < 3){
				$(shopping['teaser']).find('.storage > .content .wrapper').append(product.value);
			}
		});

		updateCounter('shopping');
	}
}

// Functie om wijzigingen aan data-attributen te detecteren
$.fn.dataTrigger = function(key, variable){
    $(this).data(key, variable).trigger('datachange');

	// Om de wijzigingen ook in de HTML-code zichtbaar te maken
	$(this).attr('data-' + key, variable);
}

$(function(){
	var now = {};
	now.date = moment().startOf('day').unix();
	now.time = moment().format('k.mm');
	now.day = moment().day();

	// Als het zondag is, stellen we de dagcode op 7 in
	if(now.day == 0){
		now.day = 7;
	}

	var open_closed = "closed";

	// We halen de standaardopeningstijden op
	var openingstijden = OPEN_CLOSED.get('OPENINGSTIJDEN')[now.day];

	// Als vandaag een afwijkende dag is, halen we deze openingstijden op
	if(now.date in OPEN_CLOSED.get('SLUITINGSDAGEN')){
		var openingstijden = OPEN_CLOSED.get('SLUITINGSDAGEN')[now.date];
	}

	if(typeof openingstijden === 'object' && openingstijden !== null){
		$(openingstijden).each(function(key, value){
			if(((typeof(value.afspraak) == 'undefined' && value.afspraak == null) || value.afspraak == false) && (value.start != value.end) && (now.time >= value.start && now.time <= value.end)){
				open_closed = "open";
			}
		});
	}

	$('.open-closed').each(function(index, value){
		var mode = $(value).data('mode');

		if(mode == 'parent'){
			if(open_closed == "open"){
				$(value).html($(value).data('open'));
			}
			else{
				$(value).html($(value).data('closed'));
			}
		}
		else{
			if(open_closed == "open"){
				$(value).find('[data-open]').show();
				$(value).find('[data-closed]').remove();
			}
			else{
				$(value).find('[data-closed]').show();
				$(value).find('[data-open]').remove();
			}
		}
	});
});

function getNoneAmount(amount){
	if(amount == 0){
		return 'geen';
	}
	else{
		return amount;
	}
}

function getSingularPlural(amount, singular, plural){
	if(amount == 0){
		return plural;
	}
	else if(amount == 1){
		return singular;
	}
	else{
		return plural;
	}
}

function queueMessage(message, title, type = 'info', html = false, onHidden = false){
	if(html){
		var message = message;
	}
	else{
		var message = '<p>' + message + '</p>';
	}

	switch(type){
		case 'success':
			toastr.success(message, title)
			break;
		case 'danger':
			toastr.error(message, title)
			break;
		case 'warning':
			toastr.warning(message, title)
			break;
		default:
			toastr.info(message, title)
	}
}

function ellipsis(target){
	var character = ' …';

	if(typeof $(target).data('ellipsis-character') !== 'undefined'){
		var character = $(target).data('ellipsis-character');
	}

	// Bewaar de oorspronkelijke tekst
	if(typeof $(target).data('ellipsis-original-text') == 'undefined'){
		$(target).data('ellipsis-original-text', $(target).text());
		$(target).attr('ellipsis-original-text', $(target).text());
	}

	var rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
	var maxWidth = parseInt($(target).css('max-width')) / rem;
	maxWidth -= 1;

	var words = $(target).data('ellipsis-original-text').split(' ');

	var counter = words[0].length;
	var spaces = 0;

	$(words).each(function(indexElement, element){
		counter += 1;

		if(counter < maxWidth && typeof words[indexElement + 1] != 'undefined'){
			counter += words[indexElement + 1].length;
		}
		else{
			return false;
		}
	});

	//console.log('Gestopt met tellen op ' + counter + '.');

	var result = $(target).data('ellipsis-original-text').substring(0, counter);
	//console.log(result);

	if(result != $(target).data('ellipsis-original-text')){
		result += character;
	}

	$(target).html(result);
}

function truncate(target){
	var elements = ['p', 'ol', 'ul', 'dl'];

	var length = 3;
	var offset = 0;
	var expandable = true;
	var forced = false;
	var url = false;
	var message = 'Lees verder&nbsp;…';
	var children = false;

	if(typeof $(target).data('truncate-length') !== 'undefined'){
		var length = $(target).data('truncate-length');
	}
	if(typeof $(target).data('truncate-offset') !== 'undefined'){
		var offset = $(target).data('truncate-offset');
	}
	if(typeof $(target).data('truncate-expandable') !== 'undefined'){
		var expandable = $(target).data('truncate-expandable');
	}
	if(typeof $(target).data('truncate-forced') !== 'undefined'){
		var forced = $(target).data('truncate-forced');
	}
	if(typeof $(target).data('truncate-url') !== 'undefined'){
		var url = $(target).data('truncate-url');
	}
	if(typeof $(target).data('truncate-message') !== 'undefined'){
		var message = $(target).data('truncate-message');
	}
	if(typeof $(target).data('truncate-children') !== 'undefined'){
		var children = $(target).data('truncate-children');
		var elements = [children];
	}

	if(forced || $(target).children(elements.join(', ')).length > (length + offset)){
		var selectors = [];
		$(elements).each(function(indexElement, element){
			selectors.push(element + ':nth-child(n+' + (length + offset + 1) + ')');
		});

		$(target).find(selectors.join(', ')).addClass('hide');
		$(target).find(elements.join(', ')).filter(':not(.hide)').addClass('show');
		$(target).find(elements.join(', ')).filter('.show').first().addClass('first');
		$(target).find(elements.join(', ')).filter('.hide').first().addClass('first');
		$(target).find(elements.join(', ')).filter('.show').last().addClass('last');
		$(target).find(elements.join(', ')).filter('.hide').last().addClass('last');

		if(expandable){
			var last_element = $(target).find(elements.join(', ')).filter('.show.last');

			if($(last_element).prop('tagName') == 'P'){
				$(last_element).append(' ').append(
					$('<a>').addClass('read-more truncate').html(
						message
					)
				);
			}
			else{
				$(target).append(
					$('<p>').append(
						$('<a>').addClass('read-more truncate').html(
							message
						)
					)
				);
			}

			if(url){
				$(target).find('.read-more.truncate').attr('href', url);
			}
			else{
				$(target).find('.read-more.truncate').on('click', function(){
					$(target).find(elements.join(', ')).show();

					$(this).remove();
				});
			}
		}
	}
	else{
		$(target).find(elements.join(', ')).addClass('show first last');
	}
}

function abate(target){
	var length = 3;
	var inline = false;

	if(typeof $(target).data('abate-length') !== 'undefined'){
		var length = $(target).data('abate-length');
	}
	if(typeof $(target).data('abate-inline') !== 'undefined'){
		var inline = $(target).data('abate-inline');
	}

	if(inline){
		$(target).addClass('d-inline');
		$(target).prev().addClass('d-inline');

		$(target).prev().html($(target).prev().html().replace(/…([^…]*)$/, ''));
	}

	$(target).find('li:nth-of-type(1n+' + (length + 1) + ')').addClass('hide');
	$(target).find('li').filter(':not(.hide)').addClass('show');

	var last_element = $(target).find(':nth-of-type(' + (length) + ')');
	$(last_element).addClass('last');
}

function imagery(target){
	$(target).find('li:first-of-type').addClass('show');
	$(target).find('li').filter(':not(.show)').addClass('hide');
}

function showErrors(errors){
	var data = errors;

	var template = templates['form']['error'];

	var content = Mustache.render(template, data);

	var dialog = bootbox.alert({
		title: CONSTANTS.get('TEMPLATES').alert.fail.title,
		message: content,
		className: 'modal-danger',
		size: 'md',
		centerVertical: true,
		buttons: {
			ok: {
				label: 'Sluit',
				className: 'btn-primary'
			}
		}
	});

	dialog.bind('shown.bs.modal', function(){
		$(this).on('click', 'a', function(event){
			var that = this;
			event.preventDefault();
			$(this).closest('.modal').modal('hide');
			$(this).closest('.modal').on('hidden.bs.modal', function(event){
				var location = that.href;
				window.location.href = location;
			})
		});
	});
}