function centerMap(){
	var bounds = new google.maps.LatLngBounds();

	$.each(markers, function (index, marker){
		bounds.extend(marker.position);
	});

	map.fitBounds(bounds);
	var listener = google.maps.event.addListener(map, 'idle', function(){
		if (map.getZoom() > 15) map.setZoom(15); 
		google.maps.event.removeListener(listener); 
	});
}

var map = $('.map.location');

if($(map).length){
	var locations = VARIABLES.get('LOCATIES');

	var map = new google.maps.Map(map[0], {
		zoom: 15,
		maxZoom: 30,
		center: new google.maps.LatLng(locations[Object.keys(locations)[0]].lat, locations[Object.keys(locations)[0]].long),
		mapTypeControl: false,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles: [
			{
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#F8F9FA"
					}
				]
			},
			{
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#6C757D"
					}
				]
			},
			{
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"color": "#F8F9FA"
					}
				]
			},
			{
				"featureType": "administrative",
				"elementType": "geometry",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "administrative.land_parcel",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#CED4DA"
					}
				]
			},
			{
				"featureType": "poi",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#DEE2E6"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#DEE2E6"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#ADB5BD"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#FFFFFF"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#DEE2E6"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#6C757D"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#ADB5BD"
					}
				]
			},
			{
				"featureType": "transit",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#DEE2E6"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#DEE2E6"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#CED4DA"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#ADB5BD"
					}
				]
			}
		]
	});

	var markerDetails = new google.maps.InfoWindow();
	var marker, counter;
	var markers = new Array();

	$.each(locations, function(index, locatie){
		marker = new google.maps.Marker({
			position: new google.maps.LatLng(locatie.lat, locatie.long),
			map: map
		});

		markers.push(marker);

		google.maps.event.addListener(marker, 'click', (function(marker, counter){
			return function(){
				route = locatie.straat_nummer + ', ' + locatie.postcode + ' ' + locatie.plaats;
				route = route.replace(/ /g,"+");

				markerDetails.setContent('<h3><a href="https://www.google.com/maps/dir/?api=1&destination=' + route + '&travelmode=driving" target="_blank">' + locatie.omschrijving + '</a></h3>' + '<p>' + locatie.straat_nummer + ' ' + '<br>' + locatie.postcode + ' ' + locatie.plaats + '</p>');
				markerDetails.open(map, marker);
			}
		})(marker, counter));

		centerMap();
	});
}