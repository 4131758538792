var allowedStorageLocations = {
	"shopping": {
		"title": "Boodschappenlijst",
		"label": "boodschappenlijst",
		"limit": 5
	},
	"comparison": {
		"title": "Vergelijkingslijst",
		"label": "vergelijkingslijst",
		"limit": 0
	}
};

var defaultStorageLocation = null;
$.each(allowedStorageLocations, function(index, storageLocation){
	defaultStorageLocation = index;
	return false;
});

var temporaryStorage = [];

$(function(){
	var temporaryStorage = initStorage();

	$.each(getStorageLocation(), function(index, storageLocation){
		updateCounter(storageLocation);
	});

	$('[data-draw-storage][data-draw-status="live"]').each(function(index){
		$(this).dataTrigger('trigger', Math.round((new Date()).getTime() / 1000));
	});
});

$('[data-init-view="true"]').on('datachange', function(event){
	$(this).find('div').html(CONSTANTS.get('TEMPLATES').load.loading.body);

	$(this).data('init-view', 'false');
	$(this).attr('data-init-view', 'false');
});

window.addEventListener('storage', (event)=>{
	var temporaryStorage = initStorage();

	// Herteken enkel de gebieden waarin een opslagwijziging optrad
	drawStorage(event.key);
});

$('[data-draw-storage]').on('datachange', function(event){
	//console.log('hierrr');
	//console.log(this);

	var storage = false;
	var status = false;
	var target = $(this);

	if(typeof $(target).data('drawStorage') !== 'undefined'){
		var storage = $(this).data('drawStorage');
	}
	if(typeof $(target).data('drawStatus') !== 'undefined'){
		var status = $(this).data('drawStatus');
	}

	if(storage){
		if(status == 'live'){
			getFromServer(storageLocation = storage, function(result){
				// Als de opslag leeg is, tonen we de standaardboodschap
				if(!result){
					var data = [
						{
							storageLocation: allowedStorageLocations['shopping']['label'],
							product: VARIABLES.get('PRODUCTEN')[VARIABLES.get('HOOFDPRODUCT')]
						}
					];

					var template = templates[$(target).data('drawStorage')][$(target).data('drawTemplate')]['empty'];
					var content = Mustache.render(template, data);

					$(target).data('drawMethod') == 'text' ? $(target).val(content) : $(target).find('div').html(content);

					return false;
				}
				// Als de opslag niet leeg is, lussen we over de items in de opslag en bouwen we het sjabloon op
				else{
					var producten = [];

					// Haal elk product in de opslag op
					$.each(result, function(index, item){
						if($(target).data('drawMethod') == 'text'){
							producten.push(buildTemplate(item, templates[$(target).data('drawStorage')][$(target).data('drawTemplate')][item.data.type], 'text'));
						}
						else{
							producten.push(buildTemplate(item, templates[$(target).data('drawStorage')][$(target).data('drawTemplate')][item.data.type]));
						}
					});

					//console.log('PRODUCTEN');
					//console.log(producten);

					//producten = sortStorage(producten, 'timestamp');
					sortStorage(producten, 'timestamp');

					$(target).data('drawMethod') == 'text' ? $(target).val(content) : $(target).find('div').html(templates[$(target).data('drawStorage')][$(target).data('drawTemplate')]['container']);

					var text = '';

					$.each(producten, function(key, product){
						//console.log(product);
						if($(target).data('drawMethod') == 'text'){
							var isLastElement = key == producten.length -1;
							text += product.value;
							if(isLastElement){
								text = $.trim(text);
								$(target).val(text);
							}
						}
						else{
							$(target).find('.wrapper').append(product.value);
						}
					});
				}
			});
		}
	}
	else{
		queueMessage('Er trad een <strong>technische fout</strong> op. Probeer het nog eens.', 'Oeps!', type = 'danger');
	}
});

function getStorageLocation(storageLocation = null){
	// Als er geen opslagnaam wordt doorgegeven, nemen we alle opslagnamen
	if(storageLocation === null){
		storageLocation = Object.keys(allowedStorageLocations);
	}
	// Als er een opslagnaam wordt doorgegeven, controleren we de geldigheid van de opslagnaam
	else{
		if($.inArray(storageLocation, allowedStorageLocations) != -1){
			storageLocation = defaultStorageLocation;
			return storageLocation;
		}
		else{
			return storageLocation;
		}
	}

	return storageLocation;
}

function initStorage(){
	$.each(allowedStorageLocations, function(indexStorageLocation, storageLocation){
		temporaryStorage[indexStorageLocation] = [];
		if(getStorage(indexStorageLocation)){
			$.each(getStorage(indexStorageLocation), function(indexItem, item){
				temporaryStorage[indexStorageLocation].push(item);
			});
		}
	});

	return temporaryStorage;
}

function getStorage(storageLocation){
	var storage = localStorage.getItem(storageLocation);

	if(getStorageSize(storage) == 0){
		storage = false;
	}
	else{
		storage = sortStorage(JSON.parse(storage), 'timestamp');
	}

	return storage;
}

function setStorage(storage, storageLocation){
	localStorage.setItem(storageLocation, JSON.stringify(storage));
}

function getStorageSize(storage){
	if(!storage || storage.length == 0){
		storageSize = 0;
	}
	else{
		storageSize = storage.length;
	}
	return storageSize;
}

function limitStorage(storageLocation){
	var storage = getStorage(storageLocation);

	if(storage == false || allowedStorageLocations[storageLocation]['limit'] == 0 || getStorageSize(storage) < allowedStorageLocations[storageLocation]['limit']){
		return false;
	}
	else{
		return true;
	}
}

function addToStorage(itemId, storageLocation, mode = 'default'){
	var item = $('[data-id="' + itemId + '"]');
	var data = $(item).data();

	// Als het item niet gevonden wordt, breken we de uitvoering af
	if(item.length = 0){
		queueMessage('Er trad een <strong>technische fout</strong> op. Probeer het nog eens.', 'Oeps!', type = 'danger');
		return false;
	}

	// Als er teveel items in de opslag zitten, breken we de uitvoering af
	if(limitStorage(storageLocation)){
		queueMessage('Je hebt momenteel <strong>' + allowedStorageLocations[storageLocation]['limit'] + ' producten</strong> in je ' + allowedStorageLocations[storageLocation]['label'] + ' zitten. Dat is het <strong>maximumaantal.</strong> Verwijder er enkele.', 'Let op!', 'warning');
		return false;
	}

	var completedParameters = true;

	// Controleer of alle productparameters zijn ingesteld, maar enkel wanneer het product aan de winkelwagen wordt toegevoegd
	if(getStorageLocation(storageLocation) == 'shopping'){
		var requiredParameters = {};

		if(typeof data['requiredParameters'] !== 'undefined'){
			var requiredParameters = JSON.parse(data['requiredParameters'].replace(/'/g, '"'));
		}

		if(jQuery.isEmptyObject(requiredParameters) == false){
			item = $('[data-id="' + itemId + '"]');
			data = $(item).data();

			$.each(requiredParameters, function(indexRequiredParameters, requiredParameter){
				$.each(requiredParameter, function(indexParameter, parameter){
					if(!data[indexParameter] && mode == 'configuration'){
						queueMessage(parameter.message, 'Let op!', 'warning');

						completedParameters = false;
					}
					else{
						completedParameters = true;
					}
				});
			});
		}
	}

	// Als er productparameters ontbreken, breken we de uitvoering af
	if(!completedParameters){
		return false;
	}

	// Voeg het product aan de opslag toe
	if(getStorageLocation(storageLocation)){
		// Bouw het product op
		var properties = {};

		$.each(data, function(indexDataAttribute, dataAttribute){
			if(indexDataAttribute.indexOf('Json') != -1){
				dataAttribute = dataAttribute.replace('}{', '}, {');
				dataAttribute = dataAttribute.replace(/'/g, '"');
				properties[indexDataAttribute] = dataAttribute;
			}
			else if(indexDataAttribute.indexOf('macy') != -1){
				return;
			}
			else{
				properties[indexDataAttribute] = dataAttribute;
			}
		});

		properties.timestamp = Date.now();

		// Controleer of het product al in de opslag aanwezig is
		var addToStorage = true;

		// Haal elk product in de opslag op
		$.each(temporaryStorage[storageLocation], function(indexItem, item){
			// Als de code van het product in de opslag gelijk is aan de code van het product wat wordt toegevoegd ...
			if(item.id == properties.id){
				// ... en het product werd aan de vergelijkingslijst toegevoegd of er zijn geen specifieke productparameters, dan negeren we de productparameters
				if(getStorageLocation(storageLocation) == 'comparison' || jQuery.isEmptyObject(requiredParameters)){
					addToStorage = false;
				}
				// ... en het product werd aan de winkelwagen toegevoegd, dan vergelijken we alle productparameters
				else if(getStorageLocation(storageLocation) == 'shopping'){
					$.each(requiredParameters, function(indexrequiredParameter, requiredParameter){
						$.each(requiredParameter, function(indexParamater, parameter){
							if(item[indexParamater] == properties[indexParamater]){
								if(addToStorage == true){
									addToStorage = false;
								}
							}
						});
					});
				}
			}
		});

		var message = {};

		if(addToStorage){
			// Voeg het product aan de tijdelijke opslag toe
			temporaryStorage[storageLocation].push(properties);
			localStorage.setItem(storageLocation, JSON.stringify(temporaryStorage[storageLocation]));

			message.message = VARIABLES.get('PRODUCTEN')[properties.type]['article'].substr(0,1).toUpperCase() + VARIABLES.get('PRODUCTEN')[properties.type]['article'].substr(1) + ' ' + properties.merk + ' ' + properties.title + '-' + VARIABLES.get('PRODUCTEN')[properties.type]['tekstlabel']['singular'] + ' ' + '<strong>werd aan je ' + allowedStorageLocations[storageLocation]['label'] + ' toegevoegd!</strong>';
			message.title = 'Gelukt!';
			message.type = 'success';
		}
		else{
			message.message = VARIABLES.get('PRODUCTEN')[properties.type]['article'].substr(0,1).toUpperCase() + VARIABLES.get('PRODUCTEN')[properties.type]['article'].substr(1) + ' ' + properties.merk + ' ' + properties.title + '-' + VARIABLES.get('PRODUCTEN')[properties.type]['tekstlabel']['singular'] + ' ' + '<strong>zat al in je ' + allowedStorageLocations[storageLocation]['label'] + '!</strong> Bekijk je <a data-type="storage" data-storage="' + storageLocation + '">' + allowedStorageLocations[storageLocation]['label'] + '</a>.';
			message.title = 'Let op!';
			message.type = 'warning';
		}

		// Licht de bijbehorende knop op
		var button = $('.storage').find('.' + storageLocation).find('button');
		breathe($(button), 5, 500, 500);

		queueMessage(message.message, message.title, message.type, false);
		
		drawStorage(storageLocation);
	}
}

function removeFromStorage(itemId, timestamp, storageLocation){
	// Haal de opslag op
	var storage = getStorage(storageLocation);

	storage = storage.filter(function(item){
		return (item.timestamp != timestamp);
	});

	// Bewaar de opslag
	setStorage(storage, storageLocation);
	initStorage();
	drawStorage(storageLocation);
	setActiveIndex('reset');
}

function sortStorage(storage, property){
	if(Object.keys(storage).length > 1){
		storage.sort(function(first, second){
			return parseInt(first[property]) - parseInt(second[property]);
		});
	}

	return storage;
}

function getFromStorage(item, storageLocation){
	var storage = getStorage(storageLocation);

	result = storage.find(haystack => haystack.id === item);

	if(result == null){
		return false;
	}
	else{
		return result;
	}
}

function clearStorage(storageLocation = null){
	storageLocations = getStorageLocation(storageLocation);
	if(!$.isArray(storageLocations)){
		storageLocations = storageLocations.split();
	}

	$.each(storageLocations, function(indexStorageLocation, storageLocation){
		localStorage.removeItem(storageLocation);
		console.log('Opslag \'' + storageLocation + '\' werd geledigd.');

		initStorage();
		drawStorage(storageLocation);
	});
}

function drawStorage(storageLocation = null){
	// Haal de opslagnaam op
	var storageLocations = getStorageLocation(storageLocation);
	if(!$.isArray(storageLocations)){
		storageLocations = storageLocations.split();
	}

	$.each(storageLocations, function(index, storageLocation){
		updateCounter(storageLocation);
		//alert(storageLocation);

		$('[data-draw-status="live"][data-draw-storage="' + storageLocation + '"]').dataTrigger('trigger', Math.round((new Date()).getTime() / 1000));
	});
}

function updateCounter(storageLocation){
	$('.storage').find('.' + storageLocation).find('.counter').each(function(){
		var storage = getStorage(storageLocation);
		var counter = Object.keys(storage).length;

		result = '';

		var offset = 0;
		var prefix = false;
		var infix = false;
		var suffix = false;
		var fallback = false;

		if(typeof $(this).data('prefix') !== 'undefined'){
			var prefix = $(this).data('prefix');
		}
		if(typeof $(this).data('infixJson') !== 'undefined'){
			var infix = JSON.parse($(this).data('infixJson').replace(/'/g, '"'));
		}
		if(typeof $(this).data('suffix') !== 'undefined'){
			var suffix = $(this).data('suffix');
		}
		if(typeof $(this).data('offset') !== 'undefined'){
			var offset = parseInt($(this).data('offset'));
			counter = counter + offset;
		}
		if(typeof $(this).data('fallback') !== 'undefined'){
			var fallback = $(this).data('fallback');
		}

		if(counter <= 0 && fallback){
			result = fallback;
		}
		else{
			result = counter;

			if(infix){
				if(counter == 1){
					result = counter + ' ' + infix.singular;
				}
				else{
					result = counter + ' ' + infix.plural;
				}
			}

			if(prefix){
				result = prefix + ' ' + result;
			}
			if(suffix){
				result = result + ' ' + suffix;
			}
		}

		$(this).html(result);
	});
}

function getFromServer(storageLocation = 'shopping', _callback){
	var storage = getStorage(storageLocation);
	var promises = [];
	var responses = [];
	var result = [];
	var message = {};

	if(!storage){
		_callback(false);
	}

	// Haal voor alle items uit de localStorage de equivalenten aan de serverkant op
	if(storage){
		storage.map(function(item){
			promises.push(
				$.ajax({
					url: item.url + '.json',
					type: 'GET',
					dataType: 'json'
				})
				.done(function(data, status, request){
					responses.push(
						{
							'data': data,
							'item': item
						}
					);
				})
				.fail(function(){
					removeFromStorage(item.id, item.timestamp, storageLocation);
				})
			);
		});

		// Wanneer alle items uit de localStorage werden opgehaald ...
		$.when
			.apply($, promises)
			.then(function(){
				$.each(responses, function(index, value){
					var go = false;

					// Controleer of de productparameters niet werden verknoeid
					if(value.item.id == value.data.id){
						//console.log('value.item.id = value.data.id');
						var requiredParameters = {};

						if(typeof value.item.requiredParameters !== 'undefined'){
							requiredParameters = JSON.parse(value.item.requiredParameters.replace(/'/g, '"'));
							console.log(requiredParameters);
						}

						//console.log('value.item');
						//console.log(value.item);
						//console.log('value.data');
						//console.log(value.data);

						// Verifieer de productparameters niet wanneer de vergelijkingslijst getoond wordt, want het huurtarief hangt soms van de gekozen productparameters af
						if(storageLocation == 'comparison' || checkHuurtarief(value.item, value.data)){
							go = true;

							var properties = {
								"data": value.data,
								"item": value.item
							};

							result.push(properties);
						}
					}
					if(!go){
						removeFromStorage(value.item.id, value.item.timestamp, storageLocation);

						message.message = 'We konden de productgegevens niet verifiëren.</strong> Het item werd <strong>van je ' + allowedStorageLocations[storageLocation]['label'] + ' verwijderd!</strong>';
						message.title = 'Oeps!';
						message.type = 'danger';

						queueMessage(message.message, message.title, message.type);
					}
				});

				if(result.length == 0){
					_callback(false);
				}
				else{
					_callback(result);
				}
			})
			.fail(function(){
				message.message = 'Er trad een <strong>technische fout</strong> op. Probeer het nog eens.';
				message.title = 'Oeps!';
				message.type = 'danger';

				queueMessage(message.message, message.title, message.type);

				_callback(false);
			}
		);
	}
}

function showEmptyWarning(storageLocation){
	var data = [
		{
			storageLocation: allowedStorageLocations[storageLocation]['label'],
			product: VARIABLES.get('PRODUCTEN')[VARIABLES.get('HOOFDPRODUCT')]
		}
	];

	var template = templates['storage']['empty'];

	var content = Mustache.render(template, data);

	var dialog = bootbox.confirm({
		title: allowedStorageLocations['shopping']['label'],
		message: content,
		className: 'modal-info',
		size: 'lg',
		centerVertical: true,
		buttons: {
			confirm: {
				label: 'Naar de ' + VARIABLES.get('PRODUCTEN')[VARIABLES.get('HOOFDPRODUCT')]['tekstlabel']['plural'],
				className: 'btn-primary'
			},
			cancel: {
				label: 'Annuleer',
				className: 'btn-secondary'
			}
		},
		callback: function(result){
			if(result){
				$(this).closest('.modal').modal('hide');
				$(this).closest('.modal').on('hidden.bs.modal', function(event){
					window.location.href = VARIABLES.get('PRODUCTEN')[VARIABLES.get('HOOFDPRODUCT')]['url'];;
				})
			}
		}
	});

	dialog.bind('shown.bs.modal', function(){
		$(this).on('click', 'a', function(event){
			var that = this;
			event.preventDefault();
			$(this).closest('.modal').modal('hide');
			$(this).closest('.modal').on('hidden.bs.modal', function(event){
				var location = that.href;
				window.location.href = location;
			})
		});
	});
}

function buildTemplate(properties, template, mode = 'html'){
	var module = {};
	module['timestamp'] = properties.item.timestamp;

	//if(properties.data.huurtarief.type == 'single'){
		properties.item.huurtarief = numeral(parseFloat(properties.item['huurtarief'])).format('0.00');
	//}

	var productlijn = $.parseHTML(Mustache.render(template, properties));

	$.each(properties.data, function(indexDataAttribute, dataAttribute){
		targetBinding = indexDataAttribute;

		if(targetBinding == 'huurtarief'){
			if(dataAttribute.type == 'single' || properties.item['huurtarief']){
				var huurtarief = properties.item['huurtarief'];
				var huurtarief = numeral(parseFloat(properties.item['huurtarief'])).format('0.00');
				var template = templates.snippets[targetBinding]['single'];
			}
			else if(dataAttribute.type == 'multiple'){
				var huurtarief = dataAttribute.single;
				var huurtarief = numeral(dataAttribute.single).format('0.00');
				var template = templates.snippets[targetBinding]['multiple'];
			}

			var rendered = Mustache.render(template, { "huurtarief": huurtarief });
			$(productlijn).find('[data-bind="' + targetBinding + '"]').html(rendered);
		}
		else{
			var targetElement = $(productlijn).find('[data-bind="' + indexDataAttribute + '"]').prop('nodeName');

			// Als array anders nix
			if(Array.isArray(dataAttribute)){
				// nu moeten we filteren
				var data = dataAttribute;

				//console.log('errree');
				//console.log(targetBinding);
				//console.log(data);
				//console.log(properties.item);
				/*const results = data.filter(obj => {
					console.log(obj);
					return obj.slug === properties.item[targetBinding];
				  });*/
				  if(targetBinding in templates.snippets){
					//console.log(targetBinding + ' gevonden in snippets');
					var snippet = templates.snippets[targetBinding];
					//console.log(snippet);
					//var rendered = Mustache.render(snippet, results);
					var rendered = Mustache.render(snippet, data);
					var contentz = rendered;
					//console.log(contentz);
				}
			}
			else if(typeof dataAttribute === 'object'){
				var data = dataAttribute;

				//console.log(targetBinding);

				if(targetBinding in templates.snippets){
					//console.log(targetBinding + ' gevonden in snippets');
					var snippet = templates.snippets[targetBinding];
					var rendered = Mustache.render(snippet, data);
					var contentz = rendered;
				}
			}
			else{
				//console.log('hiero');
				//console.log(indexDataAttribute);
				targetBinding = indexDataAttribute;
				//console.log(dataAttribute);

				if(targetBinding in templates.snippets){
					var data = dataAttribute;
					//console.log(targetBinding + ' gevonden in snippets');
					var snippet = templates.snippets[targetBinding];
					var rendered = Mustache.render(snippet, data);
					var contentz = rendered;
				}
				else{
					//console.log(targetBinding + ' niet gevonden in snippets');
					var contentz = dataAttribute;
				}
			}

			// Als we een attribuut willen wijzigen – een afbeelding, bijvoorbeeld ...
			if(typeof targetElement != 'undefined' && targetElement in CONSTANTS.get('BINDINGS')){
				$(productlijn).find('[data-bind="' + targetBinding + '"]').attr(CONSTANTS.get('BINDINGS')[targetElement], contentz);
			}
			// Als we de HTML-inhoud willen wijzigen
			else{
				$(productlijn).find('[data-bind="' + targetBinding + '"]').html(contentz);
			}
		}
	});

	if(mode == 'text'){
		module['value'] = $(productlijn).text();
	}
	else if(mode == 'html'){
		module['value'] = productlijn;
	}

	return module;
}